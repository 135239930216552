import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertContext } from 'context/alertContext';

import { useQueryParams } from 'hooks/useQueryParams';
import { useApi } from 'hooks/useApi';

import { ROUTE_NAMES } from 'Routes/routes.constants';

import { PaymentIcons } from 'components/PaymentIcons';
import { Loading } from 'components/Loading';
import { Container } from 'components/Container';
import { HeaderWithTitle } from 'components/HeaderWithTitle';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Information } from './components/Information';
import { PaymentSelect } from './components/PaymentSelect';
import { ExtraPay } from './components/ExtraPay';

import { ReactComponent as EditIco } from "components/images/edit.svg";
import { ReactComponent as TrashIco } from "components/images/trash.svg";

import { paymentTypes } from 'constants';

import { convertUTCDate, getCurrentInputDate } from 'utils/dateUtils';
import { sortArrayByValue } from 'utils/sort';

import "./styles.scss";

export const CustomerInfo = () => {
    const [data, setData] = useState(null);
    const [customerId] = useQueryParams('id');
    const [editableVisitId, setEditableVisitId] = useState(null);
    const [extraPays, setExtraPays] = useState([]);

    const dateRef = useRef();

    const { addAlert } = useContext(AlertContext);

    const initialVisitValue = {
        id: data?.id || customerId,
        name: '',
        date: new Date(new Date().toDateString()).getTime(),
        price: '',
        registeredPay: false,
        paymentMethod: paymentTypes.cash,
        extraPays: []
    };

    const [visitValues, setVisitValues] = useState(initialVisitValue);

    const formData = { ...visitValues, extraPays };

    const { getApi } = useApi();

    const navigate = useNavigate();

    useEffect(() => {
        getCustomer();
    }, []);

    useEffect(() => {
        const thisVisit = data?.visits.filter((visit) => visit.date === editableVisitId)[0];

        if (thisVisit) {
            setVisitValues({
                id: data.id,
                name: thisVisit.name,
                date: thisVisit.date,
                newDate: thisVisit.date,
                price: thisVisit.price,
                paymentMethod: thisVisit.paymentMethod,
                registeredPay: thisVisit.registeredPay,
            });

            setExtraPays(thisVisit.extraPays || []);
        }
    }, [editableVisitId]);

    const getCustomer = async () => {
        try {
            const response = await getApi(`admin/customers/${customerId}`);

            if (response.status !== 200) {
                return navigate('/');
            }

            const responseJson = await response?.json();

            setEditableVisitId(null);
            setVisitValues(initialVisitValue);
            setData(responseJson);
        } catch (error) {
            return navigate('/');
        }
    };

    const addVisitHandler = async (event) => {
        event.preventDefault();

        const response = await getApi('admin/customers/visit', 'POST', formData);

        if (response.status !== 200) {
            return;
        }

        setVisitValues({
            ...visitValues,
            price: '',
            name: '',
            paymentMethod: paymentTypes.cash,
            registeredPay: false,
        });

        setExtraPays([]);

        addAlert(`Візит ${visitValues.name} добавлений успішно`, 'success');

        getCustomer();
    };

    const modifyVisitHandler = async (event) => {
        event.preventDefault();

        const response = await getApi('admin/customers/visit', 'PUT', formData);

        if (response.status !== 200) {
            return;
        }

        addAlert(`Візит ${visitValues.name} змінено успішно`, 'success');

        setVisitValues({
            ...visitValues,
            price: '',
            name: '',
            registeredPay: false
        });
        setExtraPays([]);

        getCustomer();
        setEditableVisitId(null);
    };

    const removeCustomerVisit = async (event) => {
        const date = event.currentTarget.value;

        const response = await getApi(`admin/customers/visit/${customerId}?date=${date}`, 'DELETE');

        if (response.status !== 200) {
            return;
        }

        addAlert(`Візит ${visitValues.name} успішно видалено`, 'warn');

        getCustomer();
    };

    const changeValue = (name, value) => {
        const setValue = (value) => {
            return setVisitValues({
                ...visitValues,
                [name]: value
            });
        };

        if (name === 'registeredPay') {
            return setValue(value);
        }

        if (name === 'date' || name === 'newDate') {
            return setValue(new Date(value).getTime());
        }

        setValue(value);
    };

    const navigateToEdit = () => {
        navigate(`${ROUTE_NAMES.CUSTOMER_EDITOR}?id=${customerId}`);
    };

    const setEditableVisit = (event) => {
        const id = +event.currentTarget.value;

        if (editableVisitId === id) {
            setVisitValues(initialVisitValue);
            setExtraPays([]);
            dateRef.current.value = getCurrentInputDate();
            return setEditableVisitId(null);
        }

        dateRef.current.value = getCurrentInputDate(id);

        setEditableVisitId(id);
    };

    const addExtraPay = () => {
        setExtraPays([
            ...extraPays,
            {
                price: '',
                registeredPay: false,
                paymentMethod: paymentTypes.cash
            }
        ]);
    };

    const changeExtraPayValue = (name, value, index) => {
        setExtraPays((prevPays) => {
            const newPays = [...prevPays];
            newPays[index] = { ...newPays[index], [name]: value };

            return newPays;
        });
    };

    const removeExtraPay = (index) => {
        setExtraPays((prevPays) => [
            ...prevPays.slice(0, index),
            ...prevPays.slice(index + 1)
        ]);
    };

    if (!data) {
        return <Loading />;
    }

    return (
        <>
            <HeaderWithTitle customer={data} >
                <button className="editButton" onClick={navigateToEdit}><EditIco /> Редагувати</button>
            </HeaderWithTitle>

            <Container>
                {data.note && <p className="adminNote"><b>Нотатка:</b> {data.note}</p>}
                <Information data={data} />
                <div className="visits">
                    <div className="visits__header">
                        <h2 className="header__title">Візити ({data.visits?.length})</h2>
                        <form className={editableVisitId ? 'editableForm' : ''} onSubmit={editableVisitId ? modifyVisitHandler : addVisitHandler}>
                            <Input
                                value={visitValues.name}
                                className="form__input form__input--long"
                                onChange={(e) => changeValue('name', e.target.value)}
                                placeholder="Нотатка"
                            />
                            <Input
                                defaultValue={getCurrentInputDate()}
                                className="form__input form__input--short"
                                onChange={(e) => changeValue(editableVisitId ? 'newDate' : 'date', e.target.value)} type="date"
                                placeholder="Дата"
                                ref={dateRef}
                            />
                            <Input
                                value={visitValues.price}
                                className="form__input form__input--short"
                                type="number"
                                step="0.01"
                                min="0"
                                onChange={(e) => changeValue('price', e.target.value)}
                                placeholder="Ціна (zł)"
                            />
                            <PaymentSelect selectedValue={visitValues.paymentMethod} setSelectedValue={(value) => changeValue('paymentMethod', value)} />
                            <Input type="checkbox" className="form__input--checkbox" checked={visitValues.registeredPay}
                                onChange={(e) => changeValue('registeredPay', e.target.checked)} >
                                Зареєстрована платність?
                            </Input>

                            <Button onClick={addExtraPay} type="button" className="form__secondaryBtn">
                                Розділити оплату?
                            </Button>

                            {
                                extraPays.map((extraPay, index) =>
                                    <ExtraPay
                                        key={index}
                                        removeExtraPay={removeExtraPay}
                                        changeValue={changeExtraPayValue}
                                        data={extraPay}
                                        index={index}
                                    />
                                )
                            }

                            <Button className="form__submitBtn">
                                {editableVisitId ? 'Змінити' : 'Створити'}
                            </Button>
                        </form>
                    </div>
                    <table className='visits__table'>
                        <thead>
                            <tr>
                                <th>
                                    Назва
                                </th>
                                <th className='hideMobile'>
                                    Дата
                                </th>
                                <th>
                                    Ціна
                                </th>
                                <th>
                                    Зареєстровано?
                                </th>
                                <th>
                                    Дія
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortArrayByValue(data.visits, 'date').map((visit) =>
                                    <tr key={visit.date}>
                                        <td className='name'>
                                            {visit.name || '-'}
                                        </td>
                                        <td className='date'>
                                            {convertUTCDate(visit.date)}
                                        </td>
                                        <td className='price'>
                                            {visit.price || '-'} zł
                                            <PaymentIcons className="form__paymentsIco" paymentMethod={visit.paymentMethod} />
                                        </td>
                                        <td>
                                            {visit.registeredPay ? 'Так' : '-'}
                                        </td>
                                        <td>
                                            {
                                                visit.date === editableVisitId && <button
                                                    className='editableButton editableButton--remover'
                                                    value={visit.date}
                                                    onClick={removeCustomerVisit}
                                                >
                                                    <TrashIco />
                                                </button>
                                            }
                                            <button
                                                className={`editableButton ${visit.date === editableVisitId ? 'green' : ''}`}
                                                value={visit.date}
                                                onClick={setEditableVisit}>
                                                <EditIco />
                                            </button>
                                        </td>
                                        {
                                            visit.extraPays && visit.extraPays.map((extraPay, index) =>
                                                <td className='extraPay' key={index}>
                                                    <div className='extraPay__first'>
                                                        {extraPay.price} zł
                                                        <PaymentIcons className="form__paymentsIco" paymentMethod={extraPay.paymentMethod} />
                                                    </div>
                                                    <div className='extraPay__last'>
                                                        {extraPay.registeredPay ? 'Так' : '-'}
                                                    </div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </Container >
        </>
    );
};