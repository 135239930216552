export const infoText = [
    {
        title: "v. 1.1.1",
        message: [
            "Покращено логування в системі авторизації",
            "Додано попередження про наслідки несанкціонованого входу та статтю на сторінці логіну в програму",
            "Додано новий метод платежу",
            "Додано сторінку з інформацією про зміни у програмі",
            "Покращено прелоадер (анімацію завантаження)",
            "Додано підрахунок кількості клієнтів у фінансах",
            "Додано логіку віку клієнтів, щоб роки були динамічні (конвертує вік у рік народження і навпаки)",
            "Додано нові обробки неправильних дій та розширено логування помилок",
            "Виправлено помилки з платежами та їхніми методами",
        ],
        date: '04.2025'
    },
    {
        title: "v. 1.1.0",
        message: [
            "Рефакторинг кольорів адмін-панелі та виправлення стилів списку платежів у фінансах і історії клієнта",
            "Додано можливість вказувати метод платежу",
            "Додано логіку розділу платежів",
            "Покращено налаштування адмін-панелі",
            "Розширено функціонал запуску сервера",
        ],
        date: '03.2025'
    },
    {
        title: "v. 1.0.3",
        message: [
            "Виправлено баги із сортуванням та внесено невеликі покращення",
        ],
        date: '02.2025'
    },
    {
        title: "v. 1.0.2",
        message: [
            "Виправлено баги з пошуком, логікою показу доходу за 24 години та сортуванням списку місяців у фінансах",
            "Додано можливість пошуку за датою реєстрації",
            "Додано логіку модифікування візитів клієнта"
        ],
        date: '09.2024'
    },
    {
        title: "v. 1.0.1",
        message: [
            "Додано логіку видалення клієнтів",
            "Додано попередження, якщо клієнт із таким самим ім'ям уже існує",
            "Додано додатковий захист для програми 'Карта клієнта'",
            "Покращено базу даних, виправлено пошук"
        ],
        date: '08.2024'
    },
    {
        title: "v. 1.0.0",
        message: [
            "Додано логіку показу доходів за 24 години",
            "Виправлено помилки",
            "Додано сторінку з налаштуваннями для відображення логування програми",
            "Додано алерти",
        ],
        date: '07.2024'
    }
];