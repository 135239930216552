import { ReactComponent as ProfileIco } from "components/images/profile.svg";
import { ReactComponent as FacebookIco } from "components/images/facebook.svg";
import { ReactComponent as InstagramIco } from "components/images/instagram.svg";
import { ReactComponent as AnotherLink } from "components/images/link.svg";

import { convertUTCDate, getAgeFromYearBorn } from 'utils/dateUtils';

import "./styles.scss";

export const Information = ({ data }) => {
    const getSocialIco = (link) => {
        const include = (socialName) => {
            return link.includes(socialName);
        };

        if (include('instagram')) {
            return <InstagramIco />;
        }

        if (include('facebook')) {
            return <FacebookIco />;
        }

        return <AnotherLink />;
    };

    const arraysOfBooleans = {
        factors: [
            data.firstTime,
            data.complicationsBefore,
            data.allergic,
            data.epilepsy,
            data.diabete,
            data.pregnant,
            data.acne,
            data.rosacea,
        ],
        allows: [
            data.subscribeMarketing,
            data.subscribeAboutRecordIn24h,
            data.acceptPhotoPermission,
        ],
        contact: [
            data.acceptPhoneContact,
            data.acceptSmsContact,
            data.acceptSocialContact,
            data.anotherWayContact,
        ]
    };

    return (
        <div className="information">
            <div className="card">
                <ProfileIco className="card__avatar" />
                <h2 className="card__title">Інформація</h2>
                <p>
                    Створено: <span>
                        {convertUTCDate(data.id)}
                    </span>
                </p>
                <p className="marginTop">
                    Років: <span>
                        {data.age ? `${getAgeFromYearBorn(data.age)} (${data.age})` : ''}
                    </span>
                </p>
                <p>
                    Телефон: <span>
                        {data.phone}
                    </span>
                </p>
                <p className="marginTop card__lastText">
                    Як клієнт взнав про нас:<br />
                    <span>{data.howFind}</span>
                </p>
                {data.socialLink &&
                    <a
                        target="_blank"
                        className="socialButton"
                        href={data.socialLink}
                        rel="noreferrer"
                    >
                        {getSocialIco(data.socialLink)}
                    </a>
                }
            </div>
            {arraysOfBooleans.factors.includes(true) &&
                <div className="card">
                    <h2 className="card__title">Фактори клієнта:</h2>
                    <ul>
                        {data.firstTime && <li>Клієнт робить депіляцію вперше</li>}
                        {data.complicationsBefore && <li>Клієнт робив депіляцію раніше та виникали ускладнення</li>}
                        {data.allergic && <li>У клієнта схильність до алергічних реакцій</li>}
                        {data.epilepsy && <li>У клієнта епілепсія</li>}
                        {data.diabete && <li>У клієнта цукровий діабет</li>}
                        {data.pregnant && <li>Клієнт вагітний</li>}
                        {data.acne && <li>Клієнт приймає препарати на акне</li>}
                        {data.rosacea && <li>У клієнта Розацеа</li>}
                    </ul>
                </div>
            }
            {arraysOfBooleans.allows.includes(true) &&
                <div className="card">
                    <h2 className="card__title">Побажання і дозволи клієнта:</h2>
                    <ul>
                        {data.subscribeMarketing && <li>Клієнт хоче отримувати інформацію про акції</li>}
                        {data.subscribeAboutRecordIn24h && <li>Клієнт хоче отримувати попередження про запис за добу</li>}
                        {data.acceptPhotoPermission && <li>Клієнт погодився на фото до/після та публікацію</li>}
                    </ul>
                </div>
            }
            {arraysOfBooleans.contact.includes(true) &&
                <div className="card">
                    <h2 className="card__title">Зручний спосіб зв"язку клієнта:</h2>
                    <ul>
                        {data.acceptPhoneContact && <li>Дзвінок по телефону</li>}
                        {data.acceptSmsContact && <li>Смс</li>}
                        {data.acceptSocialContact && <li>Telegram/Instagram/Facebook</li>}
                        {data.anotherWayContact && < li > Інше: {data.anotherWayContact}</li>}
                    </ul>
                </div>
            }
        </div >
    );
};