export const url = process.env.REACT_APP_API;
export const isWithCredentials = process.env.REACT_APP_CREDENTIALS === 'true';

export const paymentTypes = {
    cash: 'cash',
    creditCard: 'creditCard',
    blik: 'blik',
    other: 'otherPay',
    blikNazar: 'blikNazar'
};

export const statusMessages = {
    e0: `З'явилися проблеми з сервером, повідомте адміністратора`,
    e1: 'Щось пішло не так',
    e2: 'Не знайдено ID або дату',
    e3: 'ID не знайдено',
    e4: 'Неповні дані',
    e5: 'Базу даних не знайдено',
    e6: 'Платежів не знайдено (критична проблема з базою даних)',

    ec0: 'Користувача не знайдено',
    ec1: 'Сталася помилка під час зміни даних клієнта',
    ec2: 'Помилка під час створення картки клієнта',
    ec3: 'Дані клієнта не знайдено',
    ec4: 'Сталася помилка під час видалення клієнта',
    ec5: 'Вік повинен містити лише цифри від 0 до 9.',

    ed0: 'Дата не може бути майбутньою',
    ed1: 'Помилка під час зміни платежу. Платіж не знайдено',

    ee0: 'Сталася помилка під час додавання витрат',
    ee1: 'Не вдалося видалити витрату',
    ee2: 'Відсутня дата витрати',

    ev0: 'Помилка під час створення візиту',
    ev1: 'Візит на цей день вже існує',
    ev2: 'Візити на цей день не знайдено',
    ev3: 'Не можна змінити платний візит на безплатний',
    ev4: 'Не можна розділити оплату візиту, якщо він не є платним',

    ei0: 'Помилка під час додавання доходу',
    ei1: 'Помилка під час зміни доходу',

    ea0: 'Пароль невірний',

    sv0: 'Безплатний візит успішно додано',
    sv1: 'Візит і платіж успішно додано',
    sv2: 'Візит успішно змінено',
    sv3: 'Безплатний візит успішно змінено',

    sc0: 'Дані клієнта успішно змінено',
    sc1: 'Клієнта успішно створено',
    sc2: 'Клієнта успішно видалено',

    sa0: 'Вхід виконано успішно',

    se0: 'Витрату успішно додано',
    se1: 'Витрату успішно видалено',

    sp0: 'Платіж успішно видалено',
};