import { useEffect, useState } from 'react';

import { useApi } from 'hooks/useApi';

import { ROUTE_NAMES } from 'Routes/routes.constants';

import { Card } from './components/Card';
import { Loading } from 'components/Loading';
import { HeaderWithTitle } from 'components/HeaderWithTitle';
import { Search } from 'components/Search';
import { Container } from 'components/Container';
import { Button } from 'components/Button';

import { ReactComponent as TrashIco } from "components/images/trash.svg";
import { ReactComponent as FilterIco } from "./filter.svg";

import { sortArrayByValue } from 'utils/sort';
import { getUTCdate } from 'utils/dateUtils';

import './styles.scss';

export const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        isOpen: false,
        registrationFrom: '',
        registrationTo: ''
    });

    const { getApi } = useApi();

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = async () => {
        try {
            const response = await getApi('admin/customers', 'GET');

            if (response?.status !== 200) {
                return;
            }

            const responseJson = await response?.json();

            if (!responseJson) {
                return;
            }

            setCustomers(sortArrayByValue(responseJson.customers, 'lastVisit'));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    if (customers && !isLoading && !customers[0]) {
        return <>
            <HeaderWithTitle title="Клієнти" >
                <Button to={ROUTE_NAMES.CUSTOMER_CREATE}>Додати клієнта</Button>
            </HeaderWithTitle>
            <Container>
                <h2>Клієнтів не знайдено</h2>
                <div>
                    <Button to={ROUTE_NAMES.CUSTOMER_CREATE}>Додати?</Button>
                </div>
            </Container>
        </>;
    }

    const searchCustomers = (array, searchString) => {
        if (!Array.isArray(array)) {
            return [];
        }

        if (!searchString && !filters.registrationFrom && !filters.registrationTo) {
            return array;
        }

        const lowerSearchString = searchString.toLocaleLowerCase();

        const dateFromUTC = getUTCdate(filters.registrationFrom);
        const dateToUTC = getUTCdate(filters.registrationTo);

        const timeZoneDifferent = Math.abs(new Date().getTimezoneOffset() * 60 * 1000);

        return array.filter((customer) => {
            if (filters.registrationFrom && customer.id <= dateFromUTC - timeZoneDifferent) {
                return false;
            }

            if (filters.registrationTo && customer.id >= dateToUTC - timeZoneDifferent) {
                return false;
            }

            const specialFormatSearch = (text = '') => {
                return text
                    .toString()
                    .toLocaleLowerCase()
                    .replaceAll(' ', '').includes(lowerSearchString.replaceAll(' ', ''));
            };

            if (lowerSearchString && !(
                specialFormatSearch(`${customer.name} ${customer.surname}`)
                || specialFormatSearch(customer.phone)
                || specialFormatSearch(customer.id)
            )) {
                return false;
            }

            return true;
        });
    };

    const toggleFiltersOpen = () => {
        setFilters({
            ...filters,
            isOpen: !filters.isOpen
        });
    };

    const filterChangeHandler = (event) => {
        const target = event.currentTarget;

        setFilters({
            ...filters,
            [target.name]: target.value
        });
    };

    const clearFilters = () => {
        setFilters({
            registrationFrom: '',
            registrationTo: '',
            isOpen: true
        });
    };

    const filteredArray = searchCustomers(customers, search);

    const filterArrayLength = filteredArray?.length;
    const customersLength = customers?.length;

    const customersNumber = `${filterArrayLength === customersLength ? '' : `${filterArrayLength} / `}${customersLength}`;

    return (
        <>
            <HeaderWithTitle title={`Клієнти (${customersNumber})`} >
                <Button to={ROUTE_NAMES.CUSTOMER_CREATE}>Додати клієнта</Button>
            </HeaderWithTitle>
            <Container>
                <div className="customersHeader">
                    <Search onChange={(e) => setSearch(e.target.value)} value={search} />
                    <button onClick={toggleFiltersOpen} className={`filterButton${filters.isOpen ? ' filterButton--active' : ''}`}>
                        <FilterIco />
                    </button>
                </div>
                {filters.isOpen &&
                    <div className="filters">
                        <label>
                            Дата регестрції з
                            <input
                                onChange={filterChangeHandler}
                                value={filters.registrationFrom}
                                name='registrationFrom'
                                type="date"
                                placeholder="mm/dd/yyyy"
                            />
                        </label>
                        <label>
                            Дата регестрції до
                            <input
                                onChange={filterChangeHandler}
                                value={filters.registrationTo}
                                name='registrationTo'
                                type="date"
                                placeholder="mm/dd/yyyy"
                            />
                        </label>
                        <button onClick={clearFilters} className='clearButton'>
                            <TrashIco />
                        </button>
                    </div>
                }
                {isLoading
                    ? <Loading />
                    : <table className="customers">
                        <thead>
                            <tr>
                                <th className="hideMobile"></th>
                                <th>Клієнт</th>
                                <th className="hideMobile">Телефон</th>
                                <th>Останній візит</th>
                                <th className="hideMobile">дії</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredArray.map((customer) =>
                                <Card
                                    key={customer.id}
                                    data={customer}
                                />
                            )}
                        </tbody>
                    </table>
                }
            </Container>
        </>
    );
};