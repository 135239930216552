import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import { useQueryParams } from 'hooks/useQueryParams';

import { Loading } from 'components/Loading';
import { HeaderWithTitle } from 'components/HeaderWithTitle';
import { Container } from 'components/Container';
import { Input } from 'components/Input';
import { Button } from 'components/Button';

import { ReactComponent as TrashIco } from "components/images/trash.svg";
import { PaymentIcons } from 'components/PaymentIcons';

import { ROUTE_NAMES } from 'Routes/routes.constants';

import { convertUTCDate, getCurrentInputDate, getUTCdate } from 'utils/dateUtils';
import { sortArrayByValue } from 'utils/sort';

import './styles.scss';

export const Finances = () => {
    const navigate = useNavigate();

    const expensesInitialFormData = {
        name: '',
        price: '',
        date: new Date(getCurrentInputDate()).getTime(),
    };

    const [paymentData, setPaymentData] = useState([]);
    const [monthsData, setMonthsData] = useState([]);
    const [month, setMonth] = useQueryParams('date', '');
    const [viewBlock, setViewBlock] = useState(0);
    const [dateView, setDateView] = useState(0);
    const [expensesFormData, setExpensesFormData] = useState(expensesInitialFormData);
    const [isLoading, setIsLoading] = useState(true);

    const { getApi } = useApi();

    useEffect(() => {
        getAllMonth();
    }, []);

    useEffect(() => {
        getPayments(month);
    }, [month]);

    const getPayments = async (date = '') => {
        try {
            const response = await getApi(`payments/getPayments${date ? `?date=${date}` : ''}`, 'GET');

            if (response.status !== 200) {
                setPaymentData({
                    income: [],
                    expenses: []
                });

                return;
            }

            const jsonResponse = await response.json();

            setPaymentData({
                income: sortArrayByValue(jsonResponse.income, 'date'),
                expenses: sortArrayByValue(jsonResponse.expenses, 'date'),
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllMonth = async () => {
        const response = await getApi('payments/getAllMonthPayments', 'GET');

        if (response.status !== 200) {
            return;
        }

        const jsonResponse = await response.json();
        setMonthsData(jsonResponse.reverse());
    };

    const addExpenses = async (event) => {
        event.preventDefault();

        const response = await getApi('payments', 'POST', {
            date: expensesFormData.date,
            name: expensesFormData.name,
            price: expensesFormData.price,
        });

        if (response.status !== 200) {
            return;
        }

        const selectedDate = new Date(expensesFormData.date);

        setExpensesFormData({
            ...expensesFormData,
            name: '',
            price: ''
        });
        setMonth(`${selectedDate.getFullYear()}.${selectedDate.getMonth() + 1}`);
        getPayments(month);
        getAllMonth();
    };

    const removeExpenses = async (event) => {
        const value = event.currentTarget.value;

        const response = await getApi(`payments?date=${value}`, 'DELETE');

        if (response.status !== 200) {
            return;
        }

        getPayments(month);
    };

    const changeExpenses = (event) => {
        const target = event.target;

        if (target.name === 'date') {
            const newData = {
                ...expensesFormData,
                [target.name]: new Date(target.value).getTime()
            };

            return setExpensesFormData(newData);
        }

        const newData = {
            ...expensesFormData,
            [target.name]: target.value
        };

        setExpensesFormData(newData);
    };

    const selectMonth = (event) => {
        setMonth(event.currentTarget.value);
    };

    const UTCtime24hAgo = getUTCdate() - (1000 * 60 * 60 * 24);

    const getFilteredDate = (array = []) => {
        if (dateView === 1) {

            return array.filter((object) => object.date > UTCtime24hAgo);
        }

        return array;
    };

    const getGeneralPriceFromArray = (array, isRegistered) => {
        let value = 0;

        const count = (object) => {
            if (isRegistered) {
                if (object.registeredPay) {
                    value = +value + +object.price;
                }

                return;
            }

            return value = +value + +object.price;
        };

        getFilteredDate(array).forEach((object) => {
            count(object);

            if (object.extraPays && object.extraPays[0]) {
                object.extraPays.forEach((extraPay) => count(extraPay));
            }
        });

        return value.toFixed(2);
    };

    const changeBlockView = (event) => {
        setViewBlock(+event.target.value);
    };

    const changeDateView = (event) => {
        setDateView(+event.target.value);
    };

    const navigateToInfo = (id) => {
        navigate(`${ROUTE_NAMES.CUSTOMER}?id=${id}`);
    };

    const allPforit = getGeneralPriceFromArray(paymentData?.income),
        registered = getGeneralPriceFromArray(paymentData?.income, true),
        expenses = getGeneralPriceFromArray(paymentData?.expenses),
        netProfit = (allPforit - expenses).toFixed(2);

    monthsData?.sort((a, b) => {
        const [yearA, monthA] = a.split('.').map(Number);
        const [yearB, monthB] = b.split('.').map(Number);

        return yearB - yearA || monthB - monthA;
    });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <HeaderWithTitle title="Фінанси" >
                <select className="dateSelector" value={month} onChange={selectMonth}>
                    {monthsData.map((month) => (
                        <option key={month} value={month} >
                            {month}
                        </option>
                    ))}
                </select>
            </HeaderWithTitle>
            <Container className="finance">
                <div className='finance__dateViewChanger'>
                    <button
                        className={dateView === 0 ? 'active' : ''}
                        onClick={changeDateView}
                        value={0}
                    >
                        Місяць
                    </button>
                    <button
                        className={dateView === 1 ? 'active' : ''}
                        onClick={changeDateView}
                        value={1}
                    >
                        24h
                    </button>
                </div>
                <div>
                    <p className='generalPrice'>Чистий прибуток: <b className='green'>{netProfit}&nbsp;zł</b></p>
                    <p className='generalPrice'>Зареєстрований прибуток: <b className='red'>{registered}&nbsp;zł</b></p>
                    <p className='generalPrice'>Візитів за місяць: <b>{paymentData.income?.length}</b></p>
                </div>
                <div className='finance__navigation'>
                    <button
                        value={0}
                        className={viewBlock === 0 ? 'active' : ''}
                        onClick={changeBlockView}
                    >
                        Дохід
                    </button>
                    <button
                        value={1}
                        className={viewBlock === 1 ? 'active' : ''}
                        onClick={changeBlockView}
                    >Витрати
                    </button>
                </div>

                <section className="finance__section">
                    <div className={`section__block section__block--first${viewBlock === 0 ? ' showSectionBlock' : ''}`}>
                        <table className='table1'>
                            <caption className='generalPrice generalPrice--special'>
                                Дохід <b className='green'>{allPforit}&nbsp;zł</b>
                            </caption>
                            <thead>
                                <tr>
                                    <th>Клієнт</th>
                                    <th>Дата</th>
                                    <th className='price'>Ціна</th>
                                    <th>Зареєстровано?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getFilteredDate(paymentData.income)[0]
                                    ? getFilteredDate(paymentData.income).map((payment) => {
                                        return (
                                            <tr key={`${payment.date} ${payment.id}`}>
                                                <td onClick={() => navigateToInfo(payment.id)} className='name'>
                                                    {payment.name}
                                                </td>
                                                <td className='date'>
                                                    {convertUTCDate(payment.date)}
                                                </td>
                                                <td className='price'>
                                                    {payment.price}&nbsp;zł <PaymentIcons paymentMethod={payment.paymentMethod} />
                                                </td>
                                                <td>
                                                    {payment.registeredPay ? 'Так' : '-'}
                                                </td>
                                                {
                                                    payment.extraPays && payment.extraPays.map((extraPay, index) =>
                                                        <td key={index} className='extraPays'>
                                                            <div className='extraPays__first'>
                                                                {extraPay.price}&nbsp;zł <PaymentIcons paymentMethod={extraPay.paymentMethod} />
                                                            </div>
                                                            <div>
                                                                {extraPay.registeredPay ? 'Так' : '-'}
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        );
                                    })
                                    : <tr>
                                        <td>Пусто</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className={`section__block section__block--second${viewBlock === 1 ? ' showSectionBlock' : ''}`}>
                        <h2 className='generalPrice generalPrice--special'>Витрати: <b className='red'>{expenses} zł</b></h2>
                        <form onSubmit={addExpenses}>
                            <Input
                                onChange={changeExpenses}
                                value={expensesFormData.name}
                                name="name"
                                placeholder="Назва"
                                className='name'
                            />
                            <Input
                                required
                                onChange={changeExpenses}
                                value={expensesFormData.price}
                                name="price"
                                type="number"
                                placeholder="Ціна"
                            />
                            <Input
                                onChange={changeExpenses}
                                name="date"
                                type="date"
                                defaultValue={getCurrentInputDate()}
                            />
                            <Button type="submit">
                                Додати
                            </Button>
                        </form>

                        <table className='table2'>
                            <tbody>
                                <tr>
                                    <th>Назва</th>
                                    <th>Дата</th>
                                    <th>Ціна</th>
                                </tr>
                                {getFilteredDate(paymentData.expenses)[0]
                                    ? getFilteredDate(paymentData.expenses).map((payment) =>
                                        <tr key={payment.date}>
                                            <td className='name'>{payment.name || '-'}</td>
                                            <td>{convertUTCDate(payment.date)}</td>
                                            <td>{payment.price} zł</td>
                                            <td>
                                                <button
                                                    className='remove'
                                                    value={payment.date}
                                                    onClick={removeExpenses}>
                                                    <TrashIco />
                                                </button>
                                            </td>
                                        </tr>)
                                    : <tr>
                                        <td>Пусто</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </section>
            </Container>
        </>
    );
};