import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'Routes/routes.constants';
import { convertUTCDate } from 'utils/dateUtils';

import { ReactComponent as EditIco } from "components/images/edit.svg";
import { ReactComponent as CustomerAvatar } from "components/images/avatar.svg";

export const Card = ({ data }) => {
    const navigate = useNavigate();

    const navigateToInfo = () => {
        navigate(`${ROUTE_NAMES.CUSTOMER}?id=${data.id}`);
    };

    const onCardClickHandler = () => {
        if (window.innerWidth < 768) {
            return;
        }

        navigateToInfo();
    };

    const onCardClickMobileHandler = () => {
        if (window.innerWidth > 768) {
            return;
        }

        navigateToInfo();
    };

    const navigateToEdit = () => {
        navigate(`${ROUTE_NAMES.CUSTOMER_EDITOR}?id=${data.id}`);
    };

    const checkIsCreatedLessThen24hAgo = () => {
        const time = new Date().getTime();

        return time - data.id < (24 * 60 * 60 * 1000);
    };

    return (
        <tr onClick={onCardClickMobileHandler}>
            <td className='avatar'>
                <button onClick={onCardClickHandler}>
                    <CustomerAvatar />
                </button>
            </td>
            <td className='name'>
                {data.name} {data.surname} {checkIsCreatedLessThen24hAgo() ? <span className='new'>Новий</span> : ''}
            </td>
            <td className='phone'>{data.phone || '-'}</td>
            <td className='date'>{convertUTCDate(data.lastVisit)}</td>
            <td className='edit'>
                <button onClick={navigateToEdit}>
                    <EditIco />
                </button>
            </td>
        </tr>
    );
};