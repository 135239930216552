import { statusMessages } from 'constants';

export const getResMessage = async (response) => {
    if (!response) {
        return statusMessages.e1;
    }

    try {
        const jsonResponse = await response.json();

        const code = jsonResponse.code;

        if (code) {
            return statusMessages[code];
        }

        return null;
    } catch (error) {
        return statusMessages.e1;
    }
};