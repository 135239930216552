import React, { useState } from 'react';

import './styles.scss';

export const CustomSelect = ({ placeholder = '', className, selectedValue, setSelectedValue, children, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    const selectedPlaceholder = placeholder || 'Виберіть';

    const getSelectedValue = () => {
        const child = children.find((child) => child.props.value === selectedValue);

        if (child) {
            return <div {...child.props} />;
        } else {
            return selectedPlaceholder;
        }
    };

    const select = (value) => {
        setIsOpen(false);
        setSelectedValue(value);
    };

    return (
        <div className='customSelector' {...props}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={`customSelector__visible${isOpen ? ' customSelector__visible--open' : ''} ${className}`}
            >
                {getSelectedValue()}
                <p className='customSelector__arrow'>
                    &#9660;
                </p>
            </div>
            {isOpen &&
                <ul className='customSelector__unvisible'>
                    {React.Children.map(children, (child, index) =>
                        React.cloneElement(child, {
                            key: index,
                            onClick: (event) => select(event.currentTarget.getAttribute('value')),
                        })
                    )}
                </ul>
            }
        </div>
    );
};