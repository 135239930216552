import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi } from '../../hooks/useApi';
import { ROUTE_NAMES } from '../../Routes/routes.constants';
import { AlertContext } from '../../context/alertContext';

import { HeaderWithTitle } from '../../components/HeaderWithTitle';
import { Container } from '../../components/Container';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

import { ReactComponent as AvatarIco } from "./avatar.svg";
import { ReactComponent as NoteIco } from "./description.svg";

import "./styles.scss";

export const CreateCustomer = () => {
    const { addAlert } = useContext(AlertContext);

    const { getApi } = useApi();

    const navigate = useNavigate();

    const newDate = new Date();

    const [approveCreate, setApproveCreate] = useState(false);
    const [customerData, setCustomerData] = useState({
        id: newDate.getTime(),
        name: '',
        age: '',
        surname: '',
    });

    const createCustomerCard = async (event) => {
        event.preventDefault();

        const custumersData = await getApi('admin/customers', 'GET');

        if (custumersData?.status !== 200) {
            return;
        }

        const custumersDataJson = await custumersData?.json();

        if (!custumersDataJson) {
            return;
        }

        const sameCustomer = custumersDataJson.customers.filter((customer) =>
            customer.name.toLowerCase() === customerData.name.toLowerCase()
            && customer.surname.toLowerCase() === customerData.surname.toLowerCase()
        )[0];

        if (sameCustomer) {
            setApproveCreate(true);

            if (!approveCreate) {
                return addAlert(`Клієнт з таким іменем вже існує, нажми ще раз "Створити" щоб продовжити`, 'warn');
            }
        }

        const response = await getApi('admin/customers', 'POST', { customerData });

        if (response.status !== 201) {
            return;
        }

        const data = await response.json();

        navigate(`${ROUTE_NAMES.CUSTOMER}?id=${data.id}`);
    };

    const changeValue = (name, value) => {
        setCustomerData({
            ...customerData,
            [name]: value.replaceAll(' ', '') || ''
        });
    };

    return (
        <>
            <HeaderWithTitle title="Створити клієнта" />
            <Container>
                <form className='createCustomerForm' onSubmit={createCustomerCard}>
                    <div className='createCustomerForm__header'>
                        <AvatarIco className="header__avatar" />
                        <Input
                            placeholder='Ім`я *'
                            required
                            value={customerData.name}
                            onChange={(e) => changeValue('name', e.currentTarget.value)}
                        />
                        <Input
                            placeholder='Прізвище *'
                            required
                            value={customerData.surname}
                            onChange={(e) => changeValue('surname', e.currentTarget.value)}
                        />
                        <Input
                            placeholder='Вік'
                            value={customerData.age}
                            onChange={(e) => changeValue('age', e.currentTarget.value)}
                            type="number"
                        />
                    </div>
                    <div className='createCustomerForm__body'>
                        <NoteIco />
                        <Input
                            textArea
                            placeholder='Нотатка'
                            value={customerData.note}
                            onChange={(e) => changeValue('note', e.currentTarget.value)}
                        />
                    </div>
                    <Button>Створити</Button>
                </form>
            </Container>
        </>
    );
};