import { ReactComponent as BlikIco } from "./icons/blick.svg";
import { ReactComponent as CreditCardIco } from "./icons/creditCard.svg";
import { ReactComponent as CashIco } from "./icons/cash.svg";
import { ReactComponent as MobilePayIco } from "./icons/mobilePay.svg";
import { ReactComponent as BlikNazarIco } from "./icons/blikNazar.svg";

import { paymentTypes } from 'constants';

export const PaymentIcons = ({ paymentMethod, ...props }) => {
    const object = {
        [paymentTypes.cash]: <CashIco {...props} />,
        [paymentTypes.creditCard]: <CreditCardIco  {...props} />,
        [paymentTypes.blik]: <BlikIco {...props} />,
        [paymentTypes.other]: <MobilePayIco {...props} />,
        [paymentTypes.blikNazar]: <BlikNazarIco {...props} />,
    };

    return (
        object[paymentMethod]
    );
};