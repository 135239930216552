import { useNavigate } from 'react-router-dom';

import { ReactComponent as CustomerAvatar } from "../images/avatar.svg";
import { ReactComponent as Arrow } from "../images/arrowLeft.svg";

import './styles.scss';

export const HeaderWithTitle = ({ title, children, customer }) => {

    const navigate = useNavigate();

    const returnHandler = () => {
        navigate(-1);
    };

    if (customer) {
        return (
            <div className="HeaderWithTitle HeaderWithTitle--customer">
                <button onClick={returnHandler} className='HeaderWithTitle__return'>
                    <Arrow />
                    Назад
                </button>
                <div className="HeaderWithTitle--customer__customer">
                    <CustomerAvatar className="customer__avatar" />
                    <h2 className="customer__name">
                        {customer.name} {customer.surname}
                    </h2>
                    <p className="customer__id">
                        ID: {customer.id}
                    </p>
                </div>
                {children}
            </div>
        );
    }

    return (
        <div className="HeaderWithTitle">
            <h1>
                {title}
            </h1>
            {children}
        </div>
    );
};