import { NavLink, useLocation } from 'react-router-dom';
import { ROUTE_NAMES } from '../../Routes/routes.constants';
import { useEffect, useState } from 'react';

import { ReactComponent as Intro } from "./img/intro.svg";
import { ReactComponent as AddUser } from "./img/addUser.svg";
import { ReactComponent as Graph } from "./img/graph.svg";
import { ReactComponent as Hamburger } from "./img/hamburger.svg";
import { ReactComponent as PersonBorder } from "./img/userWithBorder.svg";
import { ReactComponent as SettingsIco } from "./img/settings.svg";
import { ReactComponent as InfoIco } from "./img/info.svg";

import './styles.scss';

export const Navigation = () => {
    const [mobileShow, setMobileShow] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setMobileShow(false);
    }, [location.pathname]);

    const showMobileNav = () => {
        setMobileShow(true);
    };

    const hideMobileNav = () => {
        setMobileShow(false);
    };

    return (
        <>
            <nav className={`navigation${mobileShow ? ' navigation--show' : ''}`}>
                <div className="header">
                    <Intro />
                    <p>Admin Panel</p>
                </div>
                <NavLink to={ROUTE_NAMES.HOME}>
                    <PersonBorder />
                    Клієнти
                </NavLink>
                <NavLink to={ROUTE_NAMES.CUSTOMER_CREATE}>
                    <AddUser />
                    Створити клієнта
                </NavLink>
                <NavLink to={ROUTE_NAMES.FINANCES}>
                    <Graph />
                    Фінанси
                </NavLink>
                <NavLink to={ROUTE_NAMES.INFO}>
                    <InfoIco />
                    Новини
                </NavLink>
                <NavLink to={ROUTE_NAMES.SETTINGS}>
                    <SettingsIco />
                    Налаштування
                </NavLink>
                <div className='copyright'>
                    <p>@ Created by Nazar</p>
                    <p>@ Design by Valik</p>
                </div>
            </nav>
            <button onClick={showMobileNav} className='mobileButton'>
                <Hamburger />
            </button>
            <div
                onClick={hideMobileNav}
                className={`mobileBackground${mobileShow ? ' mobileBackground--show' : ''}`}
            />
        </>
    );
};