import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';

import { HeaderWithTitle } from 'components/HeaderWithTitle';
import { Container } from 'components/Container';
import { Button } from 'components/Button';

import { url } from 'constants';

import './styles.scss';

export const Settings = () => {
    const [logs, setLogs] = useState([]);

    const { getApi } = useApi();

    useEffect(() => {
        getLogsData();
    }, []);

    const getLogsData = async () => {
        const response = await getApi(`admin/logs`, 'GET');

        if (response?.status !== 200) {
            return;
        }

        setLogs(await response.json());
    };

    const getStatusClassName = (log) => {
        if (typeof log !== 'string') {
            return '';
        }

        if (log.includes('SUCCESS')) {
            return 'success';
        }

        if (log.includes('WARN')) {
            return 'warn';
        }

        if (log.includes('ATTENTION')) {
            return 'attention';
        }

        if (log.includes('ERROR')) {
            return 'error';
        }

        return '';
    };

    return (
        <>
            <HeaderWithTitle title="Налаштування" >
                <Button target="_blank" href={`${url}/admin/database`}>
                    Скачати DB
                </Button>
            </HeaderWithTitle>
            <Container className="settingsBlock">
                <div className="settingsBlock__logs">
                    <h2>Logs</h2>
                    <ul>
                        {logs.map((log, index) =>
                            <li className={getStatusClassName(log)} key={index}>{log}</li>
                        )}
                    </ul>
                </div>
            </Container>
        </>
    );
};