import { CustomSelect } from 'components/CustomSelect';
import { PaymentIcons } from 'components/PaymentIcons';

import { paymentTypes } from 'constants';

export const PaymentSelect = ({ selectedValue, setSelectedValue }) => {
    return (
        <CustomSelect
            placeholder='Спосіб платежу'
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            className="form__select"
        >
            <li value={paymentTypes.cash}>
                <PaymentIcons paymentMethod={paymentTypes.cash} /> Готівка
            </li>
            <li value={paymentTypes.creditCard}>
                <PaymentIcons paymentMethod={paymentTypes.creditCard} /> Карта
            </li>
            <li value={paymentTypes.blik}>
                <PaymentIcons paymentMethod={paymentTypes.blik} /> Blik
            </li>
            <li value={paymentTypes.blikNazar}>
                <PaymentIcons paymentMethod={paymentTypes.blikNazar} /> Blik Назару
            </li>
            <li value={paymentTypes.other}>
                <PaymentIcons paymentMethod={paymentTypes.other} /> Інше
            </li>
        </CustomSelect>
    );
};