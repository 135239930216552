import { Input } from 'components/Input';
import { PaymentSelect } from '../PaymentSelect';
import { ReactComponent as TrashIco } from "components/images/trash.svg";

import "./styles.scss";

export const ExtraPay = ({ changeValue, data, index, removeExtraPay }) => {
    if (!data) {
        return null;
    }
    return (
        <div className='form__extraPay'>
            <PaymentSelect selectedValue={data.paymentMethod} setSelectedValue={(value) => changeValue('paymentMethod', value, index)} />
            <Input
                value={data.price}
                className="form__input form__input--short"
                type="number"
                step="0.01"
                min="0"
                name="price"
                onChange={(e) => changeValue('price', e.target.value, index)}
                placeholder="Ціна"
            />
            <Input className="form__input--checkbox" checked={data.registeredPay}
                name="registeredPay"
                onChange={(e) => changeValue('registeredPay', e.target.checked, index)} type="checkbox" >
                Зареєстрована платність?
            </Input>
            <button type='button' onClick={() => removeExtraPay(index)}
                className='extraPay__removeBtn'
            >
                <TrashIco />
            </button>
        </div>
    );
};