import './styles.scss';

export const Article = ({ title, date, children }) => {
    return (
        <div className='article'>
            <h2 className='article__title'>
                {title} <span>{date}</span>
            </h2>
            <ul className='article__list'>
                {children}
            </ul>
        </div>
    );
};