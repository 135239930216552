import { ROUTE_NAMES } from './routes.constants';

import { Customers } from '../pages/Customers';
import { CustomerInfo } from '../pages/CustomerInfo';
import { Finances } from '../pages/Finances';
import { CreateCustomer } from '../pages/CreateCustomer';
import { CostumerEditor } from '../pages/CostumerEditor';
import { Settings } from '../pages/Settings';
import { Info } from 'pages/Info';

export const routes = [
    {
        path: ROUTE_NAMES.CUSTOMER,
        element: <CustomerInfo />
    },
    {
        path: ROUTE_NAMES.CUSTOMER_CREATE,
        element: <CreateCustomer />
    },
    {
        path: ROUTE_NAMES.CUSTOMER_EDITOR,
        element: <CostumerEditor />
    },
    {
        path: ROUTE_NAMES.FINANCES,
        element: <Finances />
    },
    {
        path: ROUTE_NAMES.SETTINGS,
        element: <Settings />
    },
    {
        path: ROUTE_NAMES.INFO,
        element: <Info />
    },
    {
        path: '*',
        element: <Customers />
    },
];
