import { Container } from 'components/Container';
import { HeaderWithTitle } from 'components/HeaderWithTitle';
import { Article } from './components/Article';
import { infoText } from './info';

export const Info = () => {
    return (
        <>
            <HeaderWithTitle className="test" title="Новини" />
            <Container>

                {infoText.map((article) =>
                    <Article title={article.title} date={article.date}>
                        {article.message.map((text) =>
                            <li>
                                {text}
                            </li>
                        )}
                    </Article>)}
            </Container>
        </>
    );
};